import Notifications from 'notiwind'
import number from '@coders-tm/vue-number-format'

import DashboardLayout from '@tenant/layouts/dashboard-layout.vue'
import AccountantLayout from '@tenant/layouts/accountant-layout.vue'
import AuthLayout from '@tenant/layouts/auth-layout.vue'
import BlankLayout from '@tenant/layouts/blank-layout.vue'
import AccountantInboxSidebar from '@tenant/components/partial/accountant/accountant-inbox-sidebar.vue'

export default (app) => {
    app.use(Notifications)
    app.use(number)

    app.component('accountant-inbox-sidebar', AccountantInboxSidebar)

    app.component('accountant-layout', AccountantLayout)
    app.component('dashboard-layout', DashboardLayout)
    app.component('blank-layout', BlankLayout)
    app.component('auth-layout', AuthLayout)
}
